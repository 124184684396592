@mixin grey-style-text {
	font-weight: 500;
	color: darkgrey;
}

#Search {
	min-height: calc(100vh - 60px);
	padding: 0 2em;

	h2 {
		margin: 1em 0 0;
		font-size: 2.2rem;
	}
	input {
		width: 100%;
		margin-bottom: 1em;
	}
}
ul.SearchResult {
	width: 100%;

	li {
		padding: 1em;
		display: flex;
		flex-direction: column;
		box-shadow: 2px 2px 6px rgb(0 0 0 / 20%);

		button {
			background: #12c698;
			box-shadow: inset 0 0 0 0.2rem #12c698;
		}
		h3 {
			display: flex;
			flex-wrap: wrap;

			span:nth-child(1) {
				font-size: 2rem;
				font-weight: 500;
				margin-right: 1em;
			}

			span:nth-child(2) {
				font-size: 1.5rem;
				@include grey-style-text;
				margin: auto 0;
			}
		}
		dl {
			font-size: 1.5rem;
			display: inline;
			@include grey-style-text;
			margin: 0 0 1em;

			dt {
				font-weight: bold;
				margin-top: 1em;
			}
			dd {
				margin-left: 0;
				display: flex;

				span {
					right: 1em;
				}
			}
		}
	}
}

.search__action-bar {
	display: flex;
	gap: 5px;
	> button:nth-child(1) {
		flex-basis: 85%;
	}
}
#SearchQRCode {
	.drawer {
		padding: 0;
	}
	ul {
		position: fixed;
		bottom: 0;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
}
