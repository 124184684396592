:root {
	--border-top-bottom: 25vh;
	--border-right-left: 10vh;
}

#SearchQRCode {
	.fab {
		z-index: 10 !important;
	}
}
.SearchResult {
	z-index: 10;
	background: white;
}

.QRCode_container {
	display: flex;
	width: 100%;
	height: calc(100vh - 60px);
	overflow: hidden;

	&.found {
		section > section > div {
			box-shadow: #38cf94 0px 0px 0px 2px inset !important;
		}
	}
	&.notfound {
		section > section > div {
			box-shadow: var(--primary-color) 0px 0px 0px 2px inset !important;
		}
	}
	&.pending {
		section > section > div {
			box-shadow: #879399 0px 0px 0px 2px inset !important;
		}
	}
	&.errorCode {
		section > section > div {
			box-shadow: red 0px 0px 0px 2px inset !important;
		}
		.scannerComplementoryInfo {
			span {
				background: red;
				padding: 0.5em 0.5em;
				width: 50vw;
				margin: 0 auto;
				opacity: 0.7;
			}
		}
	}
	.scannerComplementoryInfo {
		position: fixed;
		margin-top: -15vh;
		color: white;
		width: 100%;
		text-align: center;
		z-index: 1;
	}

	> div {
		width: 100vh;

		> section {
			height: calc(100vh - 60px);
			> section {
				height: calc(100vh - 60px);
				> div {
					border-top-width: var(--border-top-bottom) !important;
					border-bottom-width: var(--border-top-bottom) !important;
					border-left-width: var(--border-right-left) !important;
					border-right-width: var(--border-right-left) !important;
				}
			}
		}
	}

	button.flash-on,
	button.flash-off {
		margin-top: 20px;
		margin-left: 20px;
	}
}
