#sensorName {
	display: flex;
	font-size: 1.8rem;
	color: #4b8df9;
	justify-content: center;

	span {
		margin: auto 0;
	}
	img {
		margin-right: 5px;
	}
}
