.kpi {
	border: solid 2px $color-gray;
	border-radius: 0.8rem;
	padding: 0.8rem;
	&__infos {
		display: flex;
		justify-content: space-between;
	}
	&__icon {
		fill: $utility-color-success;
		font-size: 2.4rem;
	}
	&__date {
		color: $color-gray-dark;
		font-size: 1rem;
		font-weight: 500;
	}
	&__amount {
		font-size: 3.2rem;
		font-weight: bold;
		text-align: center;
	}
	&__desc-block {
		text-align: center;
	}
	&__desc {
		margin-top: 0.8rem;
		margin-bottom: 2.4rem;
		font-size: 1.2rem;
		font-weight: 500;
	}
	&--decrease {
		.kpi__icon {
			fill: $utility-color-error;
		}
	}
}
