.hl-filters {
	display: flex;
	padding: 1.2rem 1.6rem;
	border-bottom: 2px solid $color-gray;
	&__filter {
		margin-right: 1.6rem;
		&:last-child {
			margin-right: 0;
		}
	}
}
