@import '../../../lib/scss/variables';

.container-list-group .infinite-scroll-component {
	height: 60vh !important;
	display: flex;
	flex-direction: column;
}

.container-model-filter {
	margin: 1em 2em;
	input {
		width: 100%;
	}
}

@media (max-height: $mobile-breakpoint) {
	.container-list-group .infinite-scroll-component {
		height: 60vh !important;
		display: flex;
		flex-direction: column;
	}
}

@media (max-height: $mobile-xs-breakpoint) {
	.container-list-group .infinite-scroll-component {
		height: 45vh !important;
		display: flex;
		flex-direction: column;
	}
}
