#End {
	> div {
		height: 40vh !important;
	}
	> div:nth-child(1) {
		margin-top: 5vh !important;
	}

	div {
		h2 {
			margin: 1em 0 2em 0;
		}

		p {
			font-size: 1.8rem;
		}
		strong {
			font-weight: 600;
		}
	}
}
