.modal-container {
	height: calc(100vh - 60px);
	width: 100vw;
	display: flex;
	position: fixed;

	.modal {
		width: 90vw;
		margin: auto;
		background: white;
		border: 1px solid #ccc;
		transition: 1.1s ease-out;
		box-shadow: -0.5rem 0.5rem 0.5rem rgba(black, 0.2);
		visibility: visible;

		.content {
			padding: 2rem;
		}
	}
}
