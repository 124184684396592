.comment-modal {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 90;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100% !important;
	&__header {
		display: flex;
		align-items: flex-start;
	}
	&__title {
		font-weight: 500;
		font-size: 18px;
	}
	&__instructions {
		font-size: 16px;
	}
	&__textarea {
		width: 100%;
		height: 10rem;
		border: none;
		background: #e5f0fe;
		padding: 10px;
		margin: 15px 0;
		font-size: 14px;
	}
	&__actions {
		display: flex;
		width: 100%;
	}
	&__button {
		flex: 1;
		font-size: 16px;
		&-ok {
			padding: 5px 10px;
			border-radius: 5px;
			border: none;
			background: #4b8df9;
			color: #ffffff;
			&:disabled {
				background: #d9dde8;
				color: #1e2948;
			}
		}
		&-pass {
			color: #879399;
		}
	}
}
