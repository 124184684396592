.hl-select {
	position: relative;
	&__label {
		@include inputLabel();
	}
	&__input {
		@include inputTextInput();
		position: relative;
		text-align: left;
		color: $color-gray;
		padding-right: 3.2rem;
		&:hover {
			cursor: pointer;
		}
	}

	&__icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		font-size: 3.2rem;
		fill: $color-gray-darker;
	}
	&--active {
		.hl-select__input {
			color: $color-gray-darker;
		}
		.hl-select__icon {
			fill: $main-color-blue;
		}
	}
	&--filled {
		.hl-select__input {
			color: $color-gray-darker;
		}
	}
	@include formMessage('.hl-select');
	&--error {
		.hl-select__input {
			box-shadow: inset 0 0 0 0.2rem $utility-color-error;
		}
	}
	&--warning {
		.hl-select__input {
			box-shadow: inset 0 0 0 0.2rem $utility-color-warning;
		}
	}
}
