#WizardStepper {
	width: 30%;
	margin: 0 auto 6%;
	display: flex;
	justify-content: space-between;
	li {
		background: lightgrey;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin: 1px;
		&.active {
			background: var(--primary-color);
		}
	}
}
