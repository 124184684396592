.bm-burger-button {
	position: relative;
	width: 30px;
	height: 30px;
	left: 20px;
	top: 15px;
}

.bm-burger-bars {
	background: #69d9bf;
	height: 10% !important;
}

.bm-burger-bars-hover {
	background: var(--primary-color);
}

.bm-cross-button {
	height: 24px;
	width: 24px;
}

.bm-cross {
	background: #bdc3c7;
}

.bm-menu-wrap {
	position: fixed;
	top: 0;
	height: 100%;
}

.bm-menu {
	background: #f4f7f9;
	padding: 2.5em 1.5em 0;
	font-size: 1.15em;
}

.bm-morph-shape {
	fill: #373a47;
}

.bm-item-list {
	color: #b8b7ad;
	padding: 0;

	li {
		height: 50px;
		margin-bottom: 10px;
	}
	a,
	button {
		font-size: 2rem;
		color: var(--primary-color);
	}
}

.bm-item {
	display: inline-block;
}

.bm-overlay {
	background: rgba(0, 0, 0, 0.3);
}

#Header {
	display: flex;
	min-height: 60px;
	background: #f4f7f9;

	> div:nth-child(2) {
		flex-grow: 1;
		padding: 0 3em;
		font-weight: 800;
		color: #69d9bf;
		p {
			font-size: 1.5rem;
		}
		h1 {
			font-size: 2.5rem;
		}
	}

	button {
		margin-right: 2em;
		img {
			min-height: 18px;
		}
	}
}

#HeaderTitleWithBackButton {
	display: flex;
	margin-top: 2em;
	margin-bottom: 2em;

	svg {
		width: 50px;
		height: 50px;
	}

	h2 {
		align-self: center;
	}

	@media screen and (max-width: 400px) {
		svg {
			width: 30px;
			height: 30px;
		}
		h2 {
			font-size: 1.2em;
		}
	}
}
