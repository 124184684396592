.Organization {
	.container-model-filter {
		margin: 0;
		input {
			margin-bottom: 1em !important;
		}
	}

	ul.SearchResult li {
		margin-bottom: 1em;
	}
}
