@mixin circleIt($size) {
	width: $size;
	height: $size;
	border-radius: $size;
}

@mixin rowBackground() {
	display: grid;
	grid-gap: 2%;
	align-items: center;
	background-color: $white;
	border-radius: 0.8rem;
	margin-bottom: 0.8rem;
}

@mixin rowTitles() {
	&__title {
		font-size: 1.4rem;
		font-weight: 500;
		text-transform: uppercase;
		color: $main-color-blue;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	&__sub-title {
		font-weight: bold;
		fill: $color-gray-darker;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}

@mixin rowActionBtn() {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1.6rem;
	font-size: 2.4rem;
	@include circleIt(3.2rem);

	&:hover {
		cursor: pointer;
		background-color: $color-gray-lighter;
	}
}

@mixin formMessage($selector) {
	#{$selector}__message {
		display: block;
		font-size: 1.2rem;
		margin-top: 0.8rem;
		font-weight: 500;
	}
	&--error {
		#{$selector}__message {
			color: $utility-color-error;
		}
	}
	&--warning {
		#{$selector}__message {
			color: $utility-color-warning;
		}
	}
}

@mixin inputTextInput() {
	border: none;
	padding: 1rem;
	border-radius: 0.4rem;
	font-size: 1.4rem;
	font-weight: 500;
	background-color: $color-gray-lighter;
	color: $color-gray-darker; //tito
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

@mixin inputLabel() {
	font-size: 1.4rem;
	font-weight: 500;
	margin-bottom: 0.4rem;
	text-transform: uppercase;
}
