.App {
	height: 100vh;
}
#ContainerSelector {
	overflow: hidden;
	&.disabled {
		background: lightgrey;
	}
	#HeaderTitleWithBackButton {
		padding: 0 2em;
	}
	.createContainerPage {
		padding: 5px 0;
		height: 50%;
	}
	#container-selector__menu {
		border-bottom: 1px solid #4b8df9;
		ul {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			li {
				flex: 1;
				a {
					font-size: 14px;
					border-radius: 4px;
					color: #adadad;
					display: block;
					font-weight: 500;
					margin: 0 auto;
					text-align: center;
					padding: 0.8em;
					&.activeLink {
						color: #4b8df9;
						background: #e5f0fe;
					}
				}
			}
		}
	}
}
