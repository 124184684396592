.hl-tabs {
	display: flex;
	align-items: center;
	&__item {
		text-align: center;
		font-size: 1.4rem;
		font-weight: bold;
		padding: 0 2.4rem;
		&:hover {
			cursor: pointer;
		}
		&--active {
			color: $main-color-blue;
			&::after {
				content: '';
				display: block;
				background-color: $main-color-blue;
				width: 4rem;
				height: 0.4rem;
				margin: 0 auto;
				border-radius: 0.4rem;
			}
		}
	}
}
