.modal {
	strong {
		font-weight: 800;
	}
	h3 {
		font-size: 1.5em;
		font-weight: 500;
	}
	p {
		margin-top: 1em;
	}
	p:nth-child(3) {
		display: flex;
		align-items: center;
		color: orange;
		svg {
			margin-right: 1em;
			width: 50px;
			fill: orange;
		}
		span {
			font-weight: 800;
		}
	}
}
