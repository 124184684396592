.hl-radio {
	position: relative;
	&__label {
		display: flex;
		align-items: center;
		font-size: 1.4rem;
		font-weight: 500;
		text-transform: inherit;
		&::before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			@include circleIt(2rem);
			margin-right: 1.2rem;
			box-shadow: inset 0 0 0 0.2rem $color-gray-dark;
		}
	}
	&__input {
		position: absolute;
		top: 0;
		left: -9999px;
		&:checked + .hl-radio__label::before {
			content: '';
			box-shadow: inset 0 0 0 0.2rem $main-color-blue;
			background: radial-gradient(
				ellipse at center,
				$main-color-blue 35%,
				#ffffff 38%
			);
		}
	}
	@include formMessage('.hl-radio');
	&--error {
		.hl-radio__label::before {
			box-shadow: inset 0 0 0 0.2rem $utility-color-error;
		}
	}
	&--warning {
		.hl-radio__label::before {
			box-shadow: inset 0 0 0 0.2rem $utility-color-warning;
		}
	}
}
