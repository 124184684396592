#StartSensor {
	#networkName {
		svg {
			padding: 0.5em;
			float: right;
			position: absolute;
			right: 2em;
			margin-top: 0.6em;
		}
		label {
			flex: 1;
		}
	}
	#result {
		input {
			margin-top: 0.5em;
			width: 100%;
		}
	}

	> div {
		h2 {
			margin: 1em 0 0 0;
		}
		p:nth-child(3) {
			font-size: 1.8rem;
		}
		strong {
			font-weight: 600;
		}
	}

	.network {
		padding: 13px 20px;
		box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		margin: 20px auto;

		&-flex {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 14px;
			&--provider {
				font-size: 1.5rem;
			}
			&--img {
				width: 15px;
				height: 15px;
				margin-right: 12px;
			}

			#signalStrength {
				color: black;
			}
		}
		&--relativeDateTime {
			font-size: 1.5rem;
			> time {
				font-weight: 600;
			}
		}
	}
}
