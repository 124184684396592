#Fixation {
	div {
		h2 {
			margin: 2em 0 0.5em;
		}

		p {
			font-size: 1.8rem;
		}
		strong {
			font-weight: 600;
		}
	}
}
