.hl-sensor-row {
	display: flex;
	padding: 2.8rem 2.4rem;
	align-items: center;
	background-color: $white;
	border-radius: 0.8rem;
	margin-bottom: 0.8rem;
	&__name {
		margin-right: 5rem;
		font-weight: bold;
		font-size: 1.4rem;
	}
	&__tag {
		margin-right: 2.4rem;
	}
	&__status-tag {
		margin-right: 2.4rem;
	}
	&__col-1 {
		margin-right: 9rem;
		font-weight: 500;
	}
	&__col-2 {
		font-weight: 500;
	}
}
