.drawer {
	height: 200px;
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 1em;
	background: white;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
