.cta {
	position: relative;
	display: inline-flex;
	margin-top: 3.6rem;
	border-radius: 0.8rem;
	background-color: rgba($main-color-green, 0.2);
	&__img {
		position: absolute;
		left: 0;
		bottom: -4px;
		width: 29rem;
		object-fit: contain;
	}
	&__infos {
		display: flex;
		flex-direction: column;
		margin-left: 29rem;
		padding: 3.2rem 3.2rem 2.1rem 3.2rem;
	}
	&__title {
		font-size: 2rem;
		font-weight: 500;
		max-width: 32rem;
		margin: 1.4rem 2.8rem 2.4rem 0;
		color: $main-color-green;
	}
	&__desc {
		max-width: 32rem;
		margin: 0 2.8rem 4.1rem 0;
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 1.4;
	}
	&__action-label {
		margin-left: auto;
		color: $black;
		font-weight: bold;
		text-decoration: none;
		transition: 0.5s;
		&:hover {
			padding-right: 0.5rem;
		}
	}
	&__action-label-icon {
		margin-left: 0.8rem;
		font-size: 2.4rem;
	}
}

@media (max-width: 767.98px) {
	.cta {
		flex-direction: column;
		&__infos {
			margin-left: inherit;
			order: 1;
		}
		&__desc,
		&__title {
			margin-right: 0;
		}
		&__img {
			position: inherit;
			order: 2;
			width: 100%;
		}
	}
}
