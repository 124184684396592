h2 {
	text-align: left;
	margin-left: 1em;
	font-size: 2.5rem;
	font-weight: 500;
}

.error {
	color: #ff5e6b;
	font-weight: 500;
	margin: 2em auto;
}

.hl-input-text {
	&--success {
		.hl-input-text__input {
			box-shadow: inset 0 0 0 0.2rem #12c698;
		}
	}
}

.modal-container {
	z-index: 100;
	background: rgba(0, 0, 0, 0.5);
	height: 100vh;
}

.flex-justify-around {
	display: flex;
	justify-content: space-around;
}

@media (max-width: 400px) {
	h2 {
		font-size: 1.5em;
	}
}
