.hl-switch-btn {
	display: inline-flex;
	position: relative;
	&:hover {
		cursor: pointer;
	}
	&__label {
		font-weight: 500;
	}
	&__icon {
		position: relative;
		display: block;
		width: 3.7rem;
		height: 1.8rem;
		border-radius: 1.8rem;
		background-color: rgba($sec-color-lightblue, 0.8);
		margin-left: 1.2rem;
		&::after {
			content: '';
			position: absolute;
			left: 0.6rem;
			top: 0.5rem;
			height: 0.8rem;
			width: 0.8rem;
			border-radius: 0.8rem;
			background-color: $main-color-blue;
		}
	}
	&__input {
		position: absolute;
		top: 0;
		left: -9999px;
		&:checked + .hl-switch-btn__icon {
			background-color: $main-color-blue;
			&::after {
				left: inherit;
				right: 0.6rem;
				background-color: $white;
			}
		}
	}
	@include formMessage('.hl-switch-btn');
	&--error {
		.hl-switch-btn__label::after {
			box-shadow: inset 0 0 0 0.2rem $utility-color-error;
		}
	}
	&--warning {
		.hl-switch-btn__label::after {
			box-shadow: inset 0 0 0 0.2rem $utility-color-warning;
		}
	}
}
