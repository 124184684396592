.hl-sensor {
	display: flex;
	align-items: center;
	&__icon {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		@include circleIt(4rem);
		margin-right: 0.8rem;
		font-size: 2.4rem;
		fill: $main-color-blue;
		background-color: $sec-color-lightblue;
	}
	&__title {
		font-weight: 500;
	}
	&__battery-value {
		font-weight: 500;
	}
	&__status {
		position: absolute;
		top: 0;
		right: 0;
		@include circleIt(0.8rem);
		background-color: $utility-color-success;
	}
	&__infos {
		font-size: 1.4rem;
	}
	&__info {
		color: $color-gray;
		font-weight: 500;
	}
	&__battery-icon {
		margin-right: 0.8rem;
		fill: $utility-color-success;
		font-size: 2.4rem;
	}

	@each $key, $value in $statusColor {
		&--#{$key} {
			.hl-sensor__battery-icon {
				fill: $value;
			}
			.hl-sensor__status {
				background-color: $value;
			}
		}
	}
}
