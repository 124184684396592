@font-face {
	font-family: 'Gotham Rounded';
	src:
		url('https://public.heyliot.app/assets/common/fonts/GothamRounded-Light.woff2')
			format('woff2'),
		url('https://public.heyliot.app/assets/common/fonts/GothamRounded-Light.woff')
			format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Rounded';
	src:
		url('https://public.heyliot.app/assets/common/fonts/GothamRounded-Bold.woff2')
			format('woff2'),
		url('https://public.heyliot.app/assets/common/fonts/GothamRounded-Bold.woff')
			format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Rounded';
	src:
		url('https://public.heyliot.app/assets/common/fonts/GothamRounded-Medium.woff2')
			format('woff2'),
		url('https://public.heyliot.app/assets/common/fonts/GothamRounded-Medium.woff')
			format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Rounded';
	src:
		url('https://public.heyliot.app/assets/common/fonts/GothamRounded-Book.woff2')
			format('woff2'),
		url('https://public.heyliot.app/assets/common/fonts/GothamRounded-Book.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
}
