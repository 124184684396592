$iconSize: 2.8rem;
.hl-notification {
	position: relative;
	&__meta {
		display: flex;
		align-items: center;
	}
	&__icon {
		position: relative;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 0.6rem;
		width: $iconSize;
		height: $iconSize;
		font-size: 1.6rem;
		border-radius: $iconSize;
		background-color: rgba($sec-color-yellow, 0.3);
		fill: $sec-color-yellow;
	}
	&__date {
		display: block;
		font-size: 1.2rem;
		font-weight: 500;
	}
	&__content {
		margin-left: 3.4rem;
		padding: 0.8rem 1.6rem;
		font-size: 1.4rem;
		border-radius: 0.4rem;
		background-color: rgba($sec-color-yellow, 0.3);
		font-weight: 500;
	}
	&--collection {
		.hl-notification__icon {
			background-color: $sec-color-lightblue;
			fill: $main-color-blue;
		}
		.hl-notification__content {
			background-color: $sec-color-lightblue;
		}
	}
	&--installation {
		.hl-notification__icon {
			background-color: rgba($sec-color-pink, 0.3);
			fill: $sec-color-pink;
		}
		.hl-notification__content {
			background-color: rgba($sec-color-pink, 0.3);
		}
	}
}

.hl-timeline {
	&__notif:not(:last-child) {
		.hl-notification {
			padding-bottom: 0.8rem;
			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				left: calc(#{$iconSize} / 2);
				display: block;
				width: 1px;
				height: calc(100% - #{$iconSize});
				background-color: $color-gray;
			}
		}
	}
}
