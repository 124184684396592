.container-all {
	width: 85%;
	padding: 1.6rem 3rem;
	&__item {
		width: 100%;
		display: inline-block;
		border-bottom: 1px solid $color-gray;
		margin-bottom: 1.6rem;
	}
}

@media (max-width: 1399.98px) {
	.container-all {
		width: 100%;
	}
}

@media (max-width: 991.98px) {
	.container-all {
		padding: 1.6rem 0.8rem;
	}
}
