@import '../../../lib/scss/variables';

.createContainerPage {
	height: 83vh;
}
.recap {
	display: flex;
	flex-direction: column;
	width: 90%;
	height: 60vh;
	overflow: scroll;

	margin: 0 auto;
	justify-content: space-around;

	&__container {
		flex: 1;
		&-section {
			margin-bottom: 15px;
		}
		&-label {
			font-weight: 500;
			margin-bottom: 15px;
		}
		&-value {
			margin-left: 0;
			background: #ffffff;
			width: 100%;
			border-radius: 4px;
			padding: 10px;
			color: $main-color-blue;
			font-weight: 500;
		}
	}
	&__next-button,
	&__next-button:active,
	&__next-button:visited {
		width: 100% !important;
		background: #10c698 !important;
		box-shadow: inset 0 0 0 0.2rem #10c698 !important;
		font-weight: 500 !important;
	}
}

.createContainerSummary {
	background: $sec-color-lightblue;
	height: 83vh;
	padding: 10px;
	&__title {
		#HeaderTitleWithBackButton {
			margin-bottom: 1em;
		}
	}
}
@media (max-height: 600px) {
	.recap {
		height: 40vh;
		overflow: scroll;
		justify-content: flex-start;
	}
}
