.hl-small-container {
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: auto 6.4rem;
	border-bottom: 1px solid $color-gray-lighter;
	padding-bottom: 1.2rem;
	&__group {
		text-transform: uppercase;
		font-size: 1.4rem;
		font-weight: 500;
	}
	&__sub-title {
		font-size: 1.4rem;
		font-weight: bold;
	}
}
