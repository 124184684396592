.installations-information {
	background-repeat: no-repeat;
	background-position: 10px center;
	border: 1px solid;
	padding: 15px 10px 15px 50px;
	color: white;
	background-color: green;
	margin: 0;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 10000;
}

.SearchResult.installations {
	button {
		background-color: white;
		box-shadow: none;
	}
	.btn--small {
		min-height: 3.2rem;
		max-height: 3.2rem;
	}
	li {
		display: flex;
		flex-direction: column;
		margin-bottom: 1em;
	}

	.global-installs {
		display: flex;
		flex-direction: row;
	}

	li ul {
		margin-top: 1em;
	}

	li li {
		box-shadow: none;
		font-size: 1.5rem;
		font-weight: 300;
		margin: auto;
		flex: 1 1;
		display: flex;
		flex-direction: row;
		padding: 0 0 0 1em;
		margin-bottom: 1em;
	}

	h3,
	span {
		font-size: 1.5rem;
		font-weight: 300;
		margin: auto;
		flex: 1;
	}
}
