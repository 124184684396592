.btn.btn--retry {
	width: 100%;
	background-color: rgb(255 201 107);
	box-shadow: inset 0 0 0 0.2rem rgb(255 201 107);
	padding: 5px 1em;
	margin-top: 10px;

	&:hover {
		background-color: #ffbe4e;
		box-shadow: inset 0 0 0 0.2rem #ffbe4e;
	}
}
