$var: auto 15rem 13rem 14rem 1.6rem;

.hl-notif-report-row {
	padding: 1.6rem 2.4rem;
	grid-template-columns: $var;
	@include rowBackground();
	@include rowTitles();
	&__actions-btn {
		@include rowActionBtn();
		margin-left: auto;
	}
	&__col {
		font-weight: 500;
	}
}
