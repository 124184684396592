.hl-text-icon {
	display: inline-flex;
	align-items: center;
	&__icon {
		margin-right: 0.8rem;
		font-size: 2.4rem;
		fill: $main-color-blue;
	}
	&__label {
		font-size: 1.4rem;
		font-weight: 500;
	}
	&--inset {
		padding: 0.4rem 1.8rem 0.4rem 0.8rem;
		background-color: $white;
		border-radius: 2rem;
	}
}
