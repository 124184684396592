.hl-header {
	display: flex;
	padding: 0 4rem 0 2.8rem;
	align-items: center;
	border-bottom: 2px solid $color-gray;
	&__logo {
		margin-right: 6rem;
	}
	&__select {
		font-size: 1.4rem;
		font-weight: 500;
		text-transform: uppercase;
		color: $main-color-blue;
		&:hover {
			cursor: pointer;
			color: $main-color-blue-hover;
			.hl-header__select-icon {
				fill: $main-color-blue-hover;
			}
		}
	}
	&__select-icon {
		fill: $main-color-blue;
		font-size: 2.4rem;
	}
	&__title {
		margin: 0.4rem 0 0 0;
		font-size: 2rem;
		font-weight: bold;
		color: $main-color-green;
	}

	&__infos {
		margin: 2rem 0;
	}
	&__actions {
		display: flex;
		align-items: center;
		margin-left: auto;
	}
	&__action {
		margin-right: 1.6rem;
		&:last-child {
			margin-right: 0;
		}
	}
	&__menu {
		display: none;
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	.hl-header {
		padding: 0 1.6rem;
		&__logo {
			margin-right: 0.8rem;
		}
	}
}

@media (max-width: 767.98px) {
	.hl-header {
		&__menu {
			margin-right: auto;
			display: block;
		}
		&__actions {
			display: none;
		}
	}
}

@media (max-width: 575.98px) {
	.hl-header {
		&__select {
			font-size: 1.2rem;
		}
	}
}
