$headerheight: 9.86rem;

.app-template {
	position: relative;
	display: grid;
	grid-template-columns: min-content min-content 20fr;
	grid-template-rows: auto auto;
	grid-template-areas:
		'header header header'
		'nav tree content';

	&__header {
		grid-area: header;
		position: sticky;
		top: 0;
		background: $white;
		z-index: 10;
		height: $headerheight;
	}
	&__navigation {
		position: sticky;
		top: $headerheight;
		height: calc(100vh - #{$headerheight});
		overflow: auto;
		grid-area: nav;
	}
	&__tree {
		position: sticky;
		top: $headerheight;
		grid-area: tree;
		height: calc(100vh - #{$headerheight});
		overflow: auto;
		display: flex;
		padding: 1.6rem 2.4rem 1.6rem 3.5rem;
		background-color: $color-gray-lighter;
	}
	&__tree-filter {
		display: flex;
		align-items: center;
	}
	&__tree-expand-icon {
		margin: 1rem 0 1rem 2.4rem;
		fill: $color-gray-darker;
		font-size: 2.4rem;
		&:hover {
			cursor: pointer;
		}
	}
	&__body {
		grid-area: content;
		overflow: auto;
	}
	&__footer {
		display: none;
		position: sticky;
		bottom: 0;
		z-index: 10;
		grid-area: footer;
	}
}

.mobile-footer {
	display: flex;
	justify-content: space-evenly;
	padding: 0 1.6rem;
	background: white;
	&__action-icon {
		font-size: 2.4rem;
		fill: $main-color-blue;
	}
	&__action-btn {
		border-radius: 1.6rem;
		padding: 2rem;
		&:focus {
			cursor: pointer;
			background-color: $main-color-blue-hover;
			.mobile-footer__action-icon {
				fill: $white;
			}
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	// Remove this in a .app-template--close selector
	.app-template {
		grid-template-columns: min-content auto;
		grid-template-rows: auto auto;
		grid-template-areas:
			'header header'
			'nav content';
		&__tree {
			display: none;
		}
	}
}

@media (max-width: 767.98px) {
	.app-template {
		grid-template-columns: 1fr;
		grid-template-rows: auto auto auto;
		grid-template-areas:
			'header'
			'content'
			'footer';
		&__navigation {
			display: none;
		}
		&__actions {
			display: none;
		}
		&__footer {
			display: block;
		}
	}
}
