.hl-pagination {
	display: flex;
	align-items: center;
	&__nav-btn {
		font-size: 3.2rem;
		&:hover {
			cursor: pointer;
		}
	}
	&__pages {
		display: flex;
	}
	&__item {
		margin: 0.4rem;
		@include circleIt(3.2rem);
		background-color: $white;
		color: $color-gray-darker;
		font-size: 1.4rem;
		padding: 0.8rem;
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover {
			cursor: pointer;
			background-color: $color-gray-lighter;
		}
		&--active {
			background-color: $sec-color-lightblue;
			&:hover {
				background-color: $sec-color-lightblue;
			}
		}
	}
	&__nb {
		font-weight: bold;
	}
}

@media (max-width: 575.98px) {
	.hl-pagination {
		&__item {
			margin: 0.2rem;
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(6),
			&:nth-child(7) {
				display: none;
			}
		}
	}
}
