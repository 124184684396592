.hl-tree {
	&__lvl-2 {
		margin-left: 2.7rem;
	}
	&__lvl-3 {
		margin-left: 2.7rem;
	}
	&__li {
		margin: 1rem 0;
	}
}

.hl-tree-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	&__icon {
		fill: black;
		font-size: 2.4rem;
		margin-left: auto;
	}
}
