footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: env(safe-area-inset-bottom, 20px);
	> * {
		flex: 1;
		margin: 0.5em;
	}
}
