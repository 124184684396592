.errorBlock {
	background-repeat: no-repeat;
	background-position: 10px center;
	border: 1px solid;
	padding: 15px 10px 15px 50px;
	color: #d8000c;
	background-color: #ffbaba;
	margin: 0;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 10000;
}
