@import '../../../lib/scss/variables';

#containerSelectorName {
	display: flex;
	flex-direction: column;
	padding-top: 23px;

	div {
		width: 80%;
		margin: 0 auto;
		div[role='button'] {
			width: 50%;
			height: 50%;
		}
		h2 {
			margin-left: initial;
			padding: 10px 0;
			font-size: 20px;
		}
		.hl-input-text__input {
			width: 100%;
			margin: 13px 0 25px;
		}
		.hl-input-text__input:focus-visible {
			outline: 1px solid #d8e3f1;
		}
		button {
			width: 100%;
		}
	}
}
