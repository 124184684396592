.hl-info-graphic {
	&__btn {
		display: block;
		margin: 0 auto;
		width: 0.8rem;
		height: 0.8rem;
		border: solid 0.2rem $color-gray-darker;
		border-radius: 0.8rem;
	}
	&__tooltip {
		position: relative;
		background-color: $color-gray-darker;
		border-radius: 0.4rem;
		margin-bottom: 1.2rem;
		&::after {
			content: '';
			display: block;
			position: absolute;
			bottom: -0.8rem;
			left: 50%;
			border-top: solid 0.8rem $color-gray-darker;
			border-left: solid 0.8rem transparent;
			border-right: solid 0.8rem transparent;
			transform: translateX(-50%);
		}
	}
	&__header {
		display: flex;
		align-items: center;
		padding-top: 0.4rem;
		background-color: rgba($white, 0.1);
	}
	&__status {
		fill: $utility-color-success;
		font-size: 2.4rem;
	}
	&__name {
		font-size: 1rem;
		line-height: 1.2;
		color: $white;
	}
	&__desc {
		text-align: center;
		color: white;
		padding: 0.8rem 2.4rem;
	}
	&__date {
		display: block;
		margin: 0.4rem 0;
		font-size: 1rem;
	}
}
