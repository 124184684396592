#Login {
	> div:nth-child(1) {
		background: #f4f7f9;
		margin: 0 -2em;
		visibility: visible;
	}
	#version {
		position: fixed;
		top: 0;
		right: 0;
		display: block;
		z-index: 10000;
		font-size: 0.8em;
		padding-top: 10px;
		padding-right: 10px;
	}
	.login-background {
		height: 40vh;
		padding-bottom: 0;
		&__logo {
			width: 6em;
			height: 6em;
			position: absolute;
			top: 1em;
			left: 1em;
		}
		&__worker-image {
			object-fit: cover;
			height: 100%;
		}
	}
	.login-form {
		justify-content: flex-start;
		padding-top: 2em;
	}
	h2 {
		color: var(--primary-color);
		margin-left: 0;
	}

	> img,
	> div {
		height: 50vh;
	}
	> div {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 1em;
	}
	form {
		display: flex;
		flex-direction: column;
		#password {
			margin: 1em 0;
			display: flex;

			button {
				align-self: flex-end;
				flex: 1;
			}
			label {
				flex: 1 70%;
			}
		}
		label {
			margin-top: 1em;
		}
		div {
			text-align: left;
		}
		input {
			width: 100%;
			outline-color: #a6e9d8;
		}
		button {
			width: 100%;
			margin-top: 1em;
			.btn__label {
				display: flex;
				&--white {
					color: #ffffff;
				}
			}
			svg {
				position: relative;
				left: -1em;
			}
		}
	}
}
@media only screen and (max-height: 550px) {
	#Login {
		.login-background {
			height: 30vh;
		}
	}
}
