@import '../../lib/scss/variables';

#Geolocalisation {
	#SearchBlock {
		padding: 0;

		> div {
			> div {
				z-index: 2;

				> div {
					padding: 1em;
				}
			}
		}

		input {
			border: none;
			padding: 1rem;
			border-radius: 0.4rem;
			background-color: #f4f7f9;
			color: #1e2948;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: flex;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 0;

			span {
				font-family:
					Gotham Rounded,
					sans-serif;
				font-size: 1.5rem;
				font-weight: 500;
			}
		}
	}

	> div {
		height: 83vh;
		display: flex;
		flex-direction: column;

		#map {
			height: 70vh;
			margin: 1em -2em 2em;
		}

		h2 {
			margin: 2em 0 1em 0;
		}
	}
}

#map {
	z-index: 1;

	img.current {
		width: 100%;
		height: 100%;
	}
}

.search-bar {
	position: relative;
	width: 100%;

	&__wrapper {
		position: absolute;
		width: 100%;
		top: 4rem;
		padding: 10px;

		input {
			-webkit-box-shadow: 2px 2px 10px #808080;
			box-shadow: 2px 2px 10px #808080;
			&:focus-visible {
				outline: auto;
			}
		}
	}

	&__menu {
		background: #ffffff;
		height: max-content;
		position: fixed;
		overflow: auto;
		max-height: 30%;
		width: min-content;
	}

	&__item {
		padding: 10px;
		font-size: 16px;
		border-bottom: 1px solid $color-gray;
		background: #ffffff;
		width: fit-content;
	}
}

.update-container-geoloc {
	&__title {
		font-weight: 500;
		font-size: 2rem;
	}

	&__buttons {
		width: 100%;
		display: flex;
		margin-top: 2rem;

		&--update {
			background-color: $utility-color-success !important;
			box-shadow: inset 0 0 0 0.2rem $utility-color-success !important;
		}
	}
}

.container-marker {
	&__position {
		&--animated {
			animation: bounce-animation 1.3s ease-in-out 5;
		}
	}
}
.temporary-marker {
	&__position {
		z-index: 223;
	}
}

@keyframes bounce-animation {
	from {
		margin-top: -65px;
	}
	50% {
		margin-top: -55px;
	}
	to {
		margin-top: -65px;
	}
}

@media (max-height: 500px) {
	#Geolocalisation #SearchBlock {
		height: 78vh;
	}
}
