.hl-container-empty {
	background-color: $color-gray-lighter;
	text-align: center;

	&__img {
		width: 100%;
		padding: 1.6rem 3.6rem 0 3.6rem;
	}
	&__title {
		font-size: 2rem;
		font-weight: 500;
		color: $main-color-blue;
		margin-bottom: 1rem;
		line-height: 1.2;
	}
	&__sub-title {
		display: inline-block;
		position: relative;
		padding-bottom: 8rem;
		font-weight: 500;
	}
	&__arrow-icon {
		position: absolute;
		left: -0.8rem;
		bottom: 2.5rem;
		font-size: 4.5rem;
		fill: #4b8df9;
		transform: scaleX(-1) rotate(152deg);
	}
}
