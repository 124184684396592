.wizard-page {
	&__content {
		height: 45vh;
	}
}

@media (max-height: 700px) {
	.wizard-page {
		&__content {
			height: 58vh;
			&_small {
				height: 43vh;
			}
		}
	}
}

@media (max-height: 500px) {
	.wizard-page {
		&__content {
			height: 75vh;
			overflow: scroll;
			margin-bottom: 1em;
			&_small {
				height: 43vh;
			}
		}
	}
}
