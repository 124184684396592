#WizardValidation {
	h2 {
		margin: 1em 0 0 0;
	}

	.hl-input-text__label {
		margin-top: 10px;
	}

	input[disabled] {
		color: grey;
	}

	.actions_buttons {
		display: flex;
		margin-top: 10px;
		gap: 10px;

		button {
			border: 1px solid var(--primary-color);
			color: var(--primary-color);
			flex-grow: 1;
		}
	}
}
