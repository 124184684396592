@import '../../lib/scss/variables';

#PhotoOn {
	height: 100%;
	button {
		z-index: 10;
		margin-top: 20px;
		display: flex;
		position: fixed;
	}
	button.flash-on,
	button.flash-off {
		right: 0;
		margin: 20px;
	}

	.back {
		left: 0;
		margin-left: 20px;
		fill: $black;
		background: transparent;

		svg {
			width: 100px;
			height: 60px;
		}
	}
}

#PhotoOff {
	#Pass {
		position: absolute;
		right: 50px;
		top: 20px;
		font-size: 2rem;
		font-weight: 500;
		color: grey;
	}
	&.Visualisation {
		img {
			margin: 0 -2em;
			height: 40vh;
			object-fit: cover;
		}
	}

	div {
		h2 {
			margin: 1em 0 1em 0;
		}

		p:nth-child(2) {
			font-size: 1.8rem;
		}
		p:nth-child(3) {
			font-size: 1.5rem;
		}
		p {
			margin-bottom: 0.5em;
		}
		li {
			font-size: 1.5rem;
			display: flex;
			align-items: center;
			img {
				margin-right: 5px;
			}
		}

		strong {
			font-weight: 600;
		}
	}
}

.react-html5-camera-photo {
	display: contents !important;
}

#inner-circle {
	background: $main-color-blue !important;
}

#outer-circle {
	background-color: hsl(206, 100%, 89%) !important;
}

.full-screen-btn {
	position: absolute;
	z-index: 1000;
	right: 2rem;
	bottom: 2rem;
	background: rgba(255, 255, 255, 0.7);
	border-radius: 5px;
	line-height: 0;
	padding: 5px;
}

.icon {
	width: initial;
	height: initial;
}

#container-circles {
	bottom: 15% !important;
	position: absolute !important;
}

.picture-render {
	position: relative;
	img {
		width: 100%;
		object-fit: cover;
		height: 40vh;
	}
}

@media (max-height: 500px) {
	#PhotoOff {
		#Pass {
			position: initial;
			display: block;
			text-align: right;
			margin-top: 1em;
		}
		.wizard-page__content {
			height: 65vh;
			&_small {
				height: 35vh;
			}
		}
	}
}
