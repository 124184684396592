@import 'variables';
@import 'font';

html {
	font-size: 62.5%;
}

body {
	margin: 0;
	color: $color-gray-darker;
	font-size: 1.4rem;
}

* {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	font-family:
		Gotham Rounded,
		sans-serif;
	font-weight: normal;
	line-height: 1.43;
}
button {
	border: none;
	background: none;
	padding: 0;
}
ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
a {
	text-decoration: none;
}
p {
	margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-size: inherit;
}
@import 'mixins';
@import 'components/icon';

@import 'components/app-template';
@import 'components/button';
@import 'components/circle-text';
@import 'components/dragndrop';
@import 'components/filters';
@import 'components/input-text';
@import 'components/tag';
@import 'components/user-tag';
@import 'components/kpi';
@import 'components/container-card';
@import 'components/container-mini';
@import 'components/cta';
@import 'components/hotspot';
@import 'components/info-graphic';
@import 'components/navigation';
@import 'components/header';
@import 'components/overlay';
@import 'components/tree';
@import 'components/checkbox';
@import 'components/filter-input';
@import 'components/search-input';
@import 'components/select';
@import 'components/switch-btn';
@import 'components/notification';
@import 'components/container-row';
@import 'components/notif-report-row';
@import 'components/sensor-row';
@import 'components/user-list-row';
@import 'components/container-empty';
@import 'components/pagination';
@import 'components/radio';
@import 'components/sensor';
@import 'components/text-icon';
@import 'components/tabs';
@import 'components/small-container';

@import 'scenes/homeAdmin/home-admin';
@import 'scenes/containerOne/container-one';
@import 'scenes/containerAll/container-all';
