.hl-dragndrop {
	padding: 4rem 2.4rem 4.7rem 2.4rem;
	text-align: center;
	font-size: 1.4rem;
	border: dashed 0.2rem $main-color-blue;
	background-color: rgba($main-color-blue, 0.1);
	&__text {
		font-weight: 500;
	}
	&__icon {
		margin-bottom: 0.8rem;
		fill: $main-color-blue;
		font-size: 2.4rem;
	}
	&__link {
		display: block;
		color: $main-color-blue;
		font-weight: 500;
		text-decoration: underline;
	}
}
