.hl-container-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2.4rem 0;
	background: $white;
	&__picture-block {
		position: relative;
	}
	&__img {
		display: block;
		position: relative;
		border: 2px solid $main-color-blue;
		padding: 1rem;
		width: 16rem;
		height: 16rem;
		border-radius: 50%;
		object-fit: cover;
	}
	&__icon {
		position: absolute;
		top: 1.8rem;
		right: 1.2rem;
		border-radius: 50%;
		background-color: $utility-color-success;
		width: 2.8rem;
		height: 2.8rem;
		padding: 0.6rem;
		z-index: 10;
		fill: $white;
	}
	&__desc {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 1.6rem 0 3.6rem 0;
	}
	&__title {
		margin: 0;
		text-transform: uppercase;
		font-size: 1.4rem;
		font-weight: 500;
	}
	&__subtitle {
		margin: 0 0 1.6rem 0;
		font-size: 1.4rem;
		font-weight: bold;
	}
	&__date {
		margin-top: 0.8rem;
		font-size: 1rem;
		font-weight: 500;
		color: $color-gray-dark;
	}
	&--warning {
		.hl-container-card__icon {
			background-color: $utility-color-error;
		}
		.hl-tag--border {
			color: $utility-color-warning;
			border-color: $utility-color-warning;
			.hl-tag__left-icon {
				fill: $utility-color-warning;
			}
		}
	}
}
