.hl-filter-input {
	display: inline-flex;
	align-items: center;
	background: $white;
	padding: 0.4rem 0.8rem;
	border-radius: 0.8rem;
	&__icon {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $sec-color-lightblue;
		width: 3.2rem;
		height: 3.2rem;
		font-size: 1.6rem;
		border-radius: 3.2rem;
		margin-right: 0.8rem;
		fill: $main-color-blue;
	}
	&__input {
		font-size: 1.4rem;
		border: 0;
		font-weight: 500;

		&::placeholder {
			color: $color-gray;
		}
		&:focus {
			&::placeholder {
				color: transparent;
			}
		}
	}
	&__close-icon {
		fill: $color-gray;
		font-size: 2.5rem;
	}
}
