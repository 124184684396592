.offline-screen-hidden {
	display: none;
}
.offline-screen {
	display: block;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	text-align: center;
	color: #fff;
	padding: 10vh 5vw;
	box-sizing: border-box;
	background-color: #000;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.3);

	.browser-preview {
		margin: -45px 0 40px;
		text-decoration: underline;
		opacity: 0.8;
		text-align: right;
		color: white;
	}
	.logo-name-container {
		padding-top: 10vh;
		margin: 0 45px;
		background-size: 200px;
		font-size: 24px;
		margin-top: 15vh;
	}
	.homescreen-text {
		padding-top: 15vh;
		line-height: 1.5;
		font-size: 18px;
	}
}
.blur {
	filter: blur(10px);
	-webkit-filter: blur(10px);
	transition: 0.2s filter linear;
	-webkit-transition: 0.2s -webkit-filter linear;
}
