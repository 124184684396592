.hl-overlay {
	background: $white;
	&__header {
		font-size: 1.4rem;
		font-weight: 500;
		padding: 1.2rem 2.4rem;
		background-color: rgba($color-gray-darker, 0.1);
		border-bottom: 2px solid $main-color-blue;
		margin-bottom: -0.7rem;
	}
	&__list {
		padding: 1.4rem 0;
	}
	&__item {
		padding: 0.6rem 2.4rem;
		margin: 0.4rem 0;
		font-size: 1.4rem;
		font-weight: 500;
		color: $black;
		&:hover {
			cursor: pointer;
			background-color: $color-gray-light;
		}
		&--active {
			background-color: $main-color-blue;
			color: $white;
			&:hover {
				cursor: pointer;
				background-color: $main-color-blue-hover;
			}
		}
	}
	&__separator {
		height: 0.2rem;
		background-color: $main-color-blue;
		margin: 1.2rem 2.4rem;
	}
}
