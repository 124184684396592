.hl-checkbox {
	position: relative;
	&__label {
		display: flex;
		align-items: center;
		font-size: 1.4rem;
		font-weight: 500;
		text-transform: inherit;
		&::before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			width: 1.8rem;
			height: 1.8rem;
			margin-right: 1.2rem;
			border-radius: 0.2rem;
			box-shadow: inset 0 0 0 0.2rem $color-gray-dark;
		}
		&:hover {
			cursor: pointer;
			color: $black;
			.hl-checkbox::before {
				box-shadow: inset 0 0 0 0.2rem $color-gray-darker;
			}
		}
	}
	&__input {
		position: absolute;
		top: 0;
		left: -9999px;
		&:checked + .hl-checkbox__label::before {
			content: url('../icons/checkboxIcon.svg');
			background-color: $main-color-blue;
			box-shadow: inset 0 0 0 0.2rem $main-color-blue;
			fill: $white;
		}
	}
	&--tree-item {
		.hl-checkbox__label {
			&::before {
				box-shadow: inset 0 0 0 0.2rem $main-color-blue;
			}
			&:hover::before {
				box-shadow: inset 0 0 0 0.2rem $main-color-blue-hover;
			}
		}
	}
	@include formMessage('.hl-checkbox');
	&--error {
		.hl-checkbox__label::before {
			box-shadow: inset 0 0 0 0.2rem $utility-color-error;
		}
	}
	&--warning {
		.hl-checkbox__label::before {
			box-shadow: inset 0 0 0 0.2rem $utility-color-warning;
		}
	}
}
