.hl-input-text {
	&--full-width input {
		width: 100%;
	}

	&__label {
		@include inputLabel();
	}
	&__input {
		@include inputTextInput();
		&::placeholder {
			color: $color-gray;
		}
		&:focus {
			&::placeholder {
				color: transparent;
			}
		}
	}

	@include formMessage('.hl-input-text');
	&--error {
		.hl-input-text__input {
			box-shadow: inset 0 0 0 0.2rem $utility-color-error;
		}
	}
	&--warning {
		.hl-input-text__input {
			box-shadow: inset 0 0 0 0.2rem $utility-color-warning;
		}
	}
}
