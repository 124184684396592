button.flash-on,
button.flash-off {
	border-radius: 50%;
	width: 60px;
	height: 60px;
	display: flex;
	position: fixed;
	z-index: 10;

	svg {
		margin: auto;
	}
}

.flash-on {
	fill: white;
	background: var(--primary-color);
}
.flash-off {
	fill: white;
	background: grey;
	opacity: 0.8;
}
