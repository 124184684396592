#ContainerSelectorRename {
	input {
		width: 100%;
	}
	h2 {
		margin: 1em 0;
	}
	label {
		color: #4b8df9;
	}
	fieldset {
		margin-top: 1em;
		border: 0;
		padding-left: 0;
		label {
			margin-bottom: .5em;
			display: block;
		}
	}
}


#ContainerSelector .drawer {

	.edit {
		background: transparent;
		box-shadow: none;
		svg {
			width: 25px;
			height: 25px;
		}
	}
	button {
		background: #12c698;
		box-shadow: inset 0 0 0 0.2rem #12c698;
	}
	> div {
		display: flex;
		height: 70%;
		padding-bottom: 1em;
		padding-top: 1em;
		overflow-y: auto;
		.drawer__container-details {
			width: 100%;
		}
		div {
			display: flex;
			flex-wrap: wrap;
			align-content: flex-start;
		}
		h3 {
			font-weight: 700;
			font-size: 1.2em;
			flex: 3;
			word-break: break-all;
		}
		button.edit {
			flex: 1;
		}
		p {
			display: flex;
			width: 100%;
			align-items: center;
		}
		> img {
			width: 20%;
			margin-right: 1em;
			object-fit: cover;
		}

		ul {
			display: flex;
			flex: 1 50%;

			li {
				margin-right: 1em;
			}
		}
	}
}
#ContainerSelector {
	.drawer {
		height: auto;
		z-index: 2;
	}
	&.disabled > .drawerShadow {
		background: rgba(8, 8, 8, 0.32);
		position: absolute;
		top: 0;
		width: 100vw;
		height: 100vh;
		z-index: 1;
	}

	.Loader {
		margin: auto;
	}
	display: flex;
	flex-direction: column;
	min-height: 90vh;

	ul#containerList {
		> li {
			&:focus {
				outline: none;
			}
			margin: 1em 2em;
			min-height: 50px;
			padding: 1em;
			box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
			border-radius: 4px;
			background: #ffffff;

			h3,
			.cardTitle {
				color: #4b8df9;
				font-weight: 700;
				font-size: 1.8rem;
				flex: 1 50%;
				word-break: break-all;
			}

			p {
				color: grey;
				font-weight: 400;
			}

			img {
				width: 15px;
				margin-right: 10px;
			}
		}
	}
	.container-list {
		&__sensors {
			display: flex;
			flex-wrap: wrap;
			&-container {
				display: flex;
				flex-wrap: nowrap;
				align-items: flex-end !important;
				margin-left: 3%;
				&_icon-sensor {
					width: 15px;
					margin-right: 3px;
					font-size: 15px;
				}
			}
		}
	}
	.modal-container {
		background: rgba(0, 0, 0, 0.8);
		.modal {
			margin-top: 35%;
		}
	}
}

.containerList .infinite-scroll-component {
	display: flex;
	flex-direction: column;
}

.rename__content {
	height: 85vh;
}

@media (max-height: 600px) {
	.cover {
		height: 12vh;
	}
}

@media (max-height: 700px) {
	#ContainerSelector .modal-container .modal {
		margin-top: 20%;
	}
}

@media (min-height: 700px) {
	.rename__content {
		height: 75vh;
	}
}

@media (min-height: 912px) {
	.rename__content {
		height: 33vh;
	}
}
