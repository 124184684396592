.hl-hotspot {
	display: inline-block;
	transform: rotate(45deg);
	&__icon {
		position: relative;
		display: block;
		width: 1.9rem;
		height: 1.9rem;
		background-color: $sec-color-blue;
		border-radius: 1.9rem 1.9rem 0.2rem 1.9rem;
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0.55rem;
			left: 0.55rem;
			@include circleIt(0.8rem);
			background-color: $utility-color-success;
		}
	}
	&--warning {
		.hl-hotspot__icon::after {
			background-color: $utility-color-warning;
		}
	}
	&--error {
		.hl-hotspot__icon::after {
			background-color: $utility-color-error;
		}
	}
	&--ok-selection {
		.hl-hotspot__icon::after {
			box-shadow: inset 0 0 0 1px $white;
			background-color: $utility-color-success;
		}
		.hl-hotspot__icon::before {
			position: absolute;
			z-index: -1;
			left: 0.95rem;
			content: '';
			display: block;
			width: 3.4rem;
			height: 1.8rem;
			border-radius: 100%;
			transform: rotate(-45deg) translateX(-35%);
			background-color: rgba($utility-color-success, 0.5);
		}
	}
}
