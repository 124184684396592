.home-header {
	display: flex;
	margin: 2.4rem 0;
	justify-content: space-between;
	align-items: baseline;
	&__title {
		margin: 0;
		font-size: 1.4rem;
		font-weight: 500;
		color: $color-gray-dark;
	}
	&__hello {
		display: block;
		font-size: 3.2rem;
		font-weight: bold;
		color: $main-color-green;
		margin-bottom: 0.8rem;
	}
	&__menu {
		fill: $main-color-blue;
		font-size: 2.4rem;
		display: none;
	}
}

@media (max-width: 767.98px) {
	.home-header {
		justify-content: flex-start;
		&__title {
			margin-bottom: 0.8rem;
		}
		&__menu {
			display: block;
			margin-right: 1.6rem;
		}
	}
}
