.fab {
	position: fixed;
	bottom: 30px;
	right: 30px;

	svg {
		fill: white;
		margin: auto;
	}
}

.fab button {
	cursor: pointer;
	width: 70px;
	height: 70px;
	border-radius: 45px;
	background-color: var(--primary-color);
	border: none;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
	font-size: 24px;
	color: white;
	display: flex;
	-webkit-transition: 0.2s ease-out;
	-moz-transition: 0.2s ease-out;
	transition: 0.2s ease-out;
}

.fab button:focus {
	outline: none;
}

.fab button.main {
	position: absolute;
	width: 60px;
	height: 60px;
	border-radius: 30px;
	background-color: #5b19b7;
	right: 0;
	bottom: 0;
	z-index: 20;
}
