.hl-tag {
	position: relative;
	display: inline-flex;
	align-items: center;
	border-radius: 1.6rem;
	padding: 0.2rem 0.8rem 0.2rem 0;
	color: $white;
	font-size: 1.3rem;
	background-color: $main-color-blue;
	&__label {
		line-height: 1.2;
		font-weight: 500;
		margin: 0 auto;
	}
	&__left-icon {
		font-size: 2.4rem;
		fill: $white;
		+ .hl-tag__label {
			margin-left: -0.4rem;
		}
	}

	$heyColor: (
		'heymag': $sec-color-orange,
		'heywaste': $sec-color-pink
	);

	$statusColor: (
		'active-status': $utility-color-success,
		'warning-status': $utility-color-warning,
		'inactive-status': $utility-color-error,
		'default-status': gray

	);
	&--active {
		background-color: $utility-color-success;
		fill: $main-color-green;
	}
	&--border {
		border-radius: 0.4rem;
		padding: 0.2rem 0.8rem;
		color: $main-color-green;
		border: 2px solid $main-color-green;
		background-color: transparent;
		text-transform: uppercase;
		.hl-tag__left-icon {
			margin: 0.4rem 1.2rem 0.4rem 0;
			font-size: 1.8rem;
			fill: $main-color-green;
		}
	}
	@each $key, $value in $heyColor {
		&--#{$key} {
			background-color: rgba($value, 0.5);
			color: $color-gray-darker;
			.hl-tag__left-icon {
				fill: $color-gray-darker;
			}
		}
	}
	@each $key, $value in $statusColor {
		&--#{$key} {
			background-color: rgba($value, 0.1);
			color: $value;
			.hl-tag__left-icon {
				fill: $value;
			}
		}
	}
}
