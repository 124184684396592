$var: 9.6% 33.3% 8.7% 21.2% 11.3% auto;
.hl-container-row {
	grid-template-columns: $var;
	padding: 0.4rem;
	@include rowBackground();

	&__img {
		width: 6.4rem;
		height: 6.4rem;
		object-fit: cover;
		border-radius: 0.8rem;
	}
	@include rowTitles();
	&__time {
		font-weight: 500;
	}
	&__actions-btn {
		@include rowActionBtn();
	}
}

.hl-container-row-filters {
	display: grid;
	grid-template-columns: $var;
	grid-gap: 2%;
	font-size: 1.4rem;
	color: $color-gray-dark;
	margin-bottom: 1.6rem;
	&__item {
		font-weight: 500;
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	.hl-container-row-filters {
		display: none;
	}
	.hl-container-row {
		grid-template-columns: 20% 20% 20% 20% 20%;
		grid-gap: 0.2rem;
		grid-template-rows: auto auto;
		grid-template-areas:
			'img title title title title'
			'status fill-lvl time time actions-btn';
		row-gap: 0.8rem;
		&__img {
			grid-area: img;
		}
		&__titles {
			grid-area: title;
		}
		&__fill-lvl {
			grid-area: fill-lvl;
		}
		&__status {
			grid-area: status;
		}
		&__time {
			grid-area: time;
		}
		&__actions-btn {
			grid-area: actions-btn;
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 575.98px) {
	.hl-container-row-filters {
		display: none;
	}
	.hl-container-row {
		grid-template-columns: 10% 10% 70% 10%;
		grid-template-rows: auto auto auto;
		grid-template-areas:
			'status title title actions-btn'
			'img img fill-lvl fill-lvl'
			'time time time time';
		grid-gap: 0.2rem;
		row-gap: 0.8rem;
		&__fill-lvl {
			display: flex;
			justify-content: center;
			align-self: stretch;
			padding: 1.2rem;
			.hl-tag {
				width: 100%;
				max-width: 200px;
				height: 100%;
				&__label {
					font-size: 1.6rem;
				}
			}
		}
		&__status {
			.hl-tag {
				padding: 0;
				&__label {
					display: none;
				}
			}
		}
		&__actions-btn {
			justify-self: start;
		}
	}
}
