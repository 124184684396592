@import 'components/home-header';

.home-admin {
	display: flex;
	width: 100%;
	max-width: 1074px;
	margin: 0 auto;

	&__page {
		width: 100%;
		padding-right: 1.6rem;
	}
	&__navigation {
		margin-top: 9rem;
		margin-right: 10rem;
	}
	&__sub-title {
		margin: 0 0 1.6rem 0;
		font-size: 2rem;
		font-weight: bold;
	}
	&__kpi-list {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 2.4rem;
		padding-bottom: 3.2rem;
		border-bottom: 1px solid $color-gray;
		margin-bottom: 3.2rem;
	}
	&__kpi-item {
		flex: 1;
	}
	&__section {
		padding-bottom: 4rem;
		border-bottom: 1px solid $color-gray;
		margin-bottom: 3.2rem;
		&:last-of-type {
			border: none;
		}
	}
	&__fav-containers-list {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-gap: 2.4rem;
	}
	&__map-container {
		margin-right: 3.6rem;
	}
	&__map {
		width: 100%;
		height: 100%;
		border-radius: 0.8rem;
		object-fit: cover;
	}
	&__containers-location {
		display: flex;
		padding: 1.6rem 4rem 1.6rem 1.6rem;
		border-radius: 8px;
		border: solid 2px $color-gray;
	}
	&__container-card {
		min-width: 29rem;
	}
	.navigation__item--expand {
		display: none;
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	.home-admin {
		&__navigation {
			margin-right: 0.8rem;
		}
		&__fav-containers-list {
			grid-template-columns: repeat(4, 1fr);
		}
		&__containers-location {
			padding: 1.6rem;
		}
		&__container-card {
			min-width: inherit;
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	.home-admin {
		&__kpi-list {
			grid-template-columns: repeat(2, 1fr);
		}
		&__fav-containers-list {
			grid-template-columns: repeat(3, 1fr);
		}
		&__containers-location {
			padding: 1.6rem;
			flex-direction: column;
		}
		&__section--explore-map {
			display: none;
		}
		&__navigation {
			display: none;
		}
		&__page {
			padding: 0 1.6rem;
		}
	}
}

@media (max-width: 575.98px) {
	.home-admin {
		&__kpi-list {
			grid-template-columns: repeat(1, 1fr);
		}
		&__fav-containers-list {
			grid-template-columns: repeat(2, 1fr);
		}
		&__map-container {
			margin-right: 0;
		}
	}
}

@media (max-width: 420px) {
	.home-admin {
		&__fav-containers-list {
		}
	}
}
