.hl-container-mini {
	text-align: center;
	&__title {
		margin: 0.4rem 0 0.8rem 0;
		font-size: 1.4rem;
		font-weight: bold;
	}
	&__img-wrapper {
		position: relative;
		padding-top: 100%;
	}
	&__img {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		object-fit: cover;
		width: 100%;
		height: 100%;
		border-radius: 0.4rem;
	}
}
