.signal-strength {
  font-size: 1.5rem;

  button {
    background: unset !important;
    box-shadow: unset !important;
  }
  span {
    color: darkgrey;
  }
}
.signal-strength--img {
  width: 15px;
  height: 15px;
  margin-right: 12px;
}
