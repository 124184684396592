.user-tag {
	position: relative;
	border-radius: 1.6rem;
	padding: 0.2rem 3rem 0.2rem 0.8rem;
	font-size: 1.3rem;
	border: $border-green-1;
	color: $color-gray-darker;

	&__label {
		line-height: 1.2;
		font-weight: 500;
	}

	&__close-btn {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		&:hover {
			cursor: pointer;
		}
	}

	&__icon {
		font-size: 1.8em;
		fill: $color-gray-darker;
		margin: 0 0.4rem;
	}
	&--error {
		border-color: $utility-color-error;
	}
}
