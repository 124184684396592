.navigation {
	display: inline-flex;
	height: 100%;
	padding: 0.8rem;
	&--is-expanded {
		.navigation-item {
			display: flex;
			&__label {
				display: block;
			}
		}
	}
	&__list {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	&__options {
		margin-top: 4rem;
	}
	&__item--expand {
		display: none;
		transform: rotate(180deg);
	}
}

.navigation-item {
	display: inline-flex;
	align-items: center;
	border-radius: 1.6rem;
	padding: 2rem;
	&:hover {
		background-color: $color-gray-lighter;
	}
	&--is-active {
		background-color: $main-color-blue;
		.navigation-item__label {
			color: $white;
		}
		.navigation-item__icon {
			fill: $white;
		}
		&:hover {
			background-color: $main-color-blue-hover;
		}
	}

	&__label {
		display: none;
		flex: 1;
		font-size: 1.4rem;
		font-weight: 500;
		color: $main-color-blue;
		margin-left: 2.4rem;
	}
	&__icon {
		font-size: 2.4rem;
		fill: $main-color-blue;
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	// Remove this in a .app-template--close selector
	.navigation {
		&__item--expand {
			display: block;
		}
	}
}

@media (max-width: 767.98px) {
	.navigation-item {
		padding: 1.2rem;
	}
}
