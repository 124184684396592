.container-one {
	width: 100%;
	&__map-header {
		width: 100%;
		height: 200px;
		object-fit: cover;
	}
	&__map-block {
		position: relative;
	}
	.hl-text-icon--container-map {
		position: absolute;
		bottom: 1.6rem;
		left: 1.6rem;
		margin-right: 1.6rem;
	}
	&__content {
		width: 80%;
		max-width: 100rem;
		margin: 0 auto;
	}
	&__localisation {
		text-transform: uppercase;
		font-size: 1.4rem;
		font-weight: 500;
		color: $color-gray-darker;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	&__header {
		position: relative;
		display: grid;
		grid-template-columns: calc(100% - 25.2rem - 3rem) 25.2rem;
		grid-gap: 3rem;
		align-items: flex-end;
		margin: -9rem 0 2.4rem 0;
	}
	&__container-img {
		position: relative;
		z-index: 1;
		width: 25.2rem;
		height: 25.2rem;
		border-radius: 0.4rem;
		border: 2px solid $main-color-green;
	}
	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&__sensors {
		display: flex;
		flex-wrap: wrap;
	}
	&__sensor {
		margin-bottom: 0.8rem;
		margin-right: 3.2rem;
		&:last-of-type {
			margin-right: 0;
		}
	}
	&__monitoring {
		padding: 1.6rem 4rem 1.6rem 1.6rem;
		border-radius: 8px;
		border: solid 2px $color-gray;
	}
	&__monitoring-img {
		width: 100%;
		object-fit: cover;
	}
	&__title {
		position: relative;
		font-weight: bold;
		font-size: 2.6rem;
		margin-bottom: 0.4rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		padding-right: 4rem;
	}
	&__fav-btn {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		font-size: 2.4rem;
		fill: $sec-color-yellow;
	}
	&__tags {
		display: flex;
		margin-bottom: 0.8rem;
	}
	&__tag {
		margin-right: 0.8rem;
	}

	&__section-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1.6rem;

		.container-one__sub-title {
			margin-bottom: 0;
		}
	}
	&__sub-title {
		font-size: 2rem;
		font-weight: bold;
		margin-bottom: 1.6rem;
		margin-right: 0.8rem;
	}
	&__section {
		padding-bottom: 2.4rem;
		border-bottom: 1px solid $color-gray;
		margin-bottom: 2.4rem;

		&:last-of-type {
			padding: 0;
			border: none;
		}
	}
	&__pagination {
		display: flex;
		justify-content: center;
		margin-bottom: 2.4rem;
	}
}

@media (max-width: 767.98px) {
	.container-one {
		&__header {
			margin-top: 0;

			grid-template-columns: 100%;
		}
		&__details {
			width: 100%;

			margin-right: inherit;
			margin-bottom: 1.6rem;
		}
		&__container-img {
			width: 100%;
			position: inherit;
			top: inherit;
		}
	}
}

@media (max-width: 991.98px) {
	.container-one {
		&__title {
			font-size: 2.2rem;
		}
		&__content {
			width: inherit;
			max-width: inherit;
			padding: 1.6rem 1.6rem;
		}
	}
}

@media (max-width: 767.98px) {
	.container-one {
		&__title {
			text-overflow: inherit;
			overflow: inherit;
			white-space: inherit;
		}
		&__container-img {
			width: 100%;
		}
	}
}

@media (max-width: 575.98px) {
	.container-one {
		&__title {
			font-size: 2rem;
		}
		&__localisation {
			font-size: 1.2rem;
		}
		&__section-header {
			flex-direction: column;
		}
	}
}
